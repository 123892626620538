<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M11 21.5c5.799 0 10.5-4.701 10.5-10.5S16.799.5 11 .5.5 5.201.5 11 5.201 21.5 11 21.5zm0-1a9.5 9.5 0 1 1 0-19 9.5 9.5 0 0 1 0 19z"
    />
    <path
      d="M8 8.933C8.06 7.264 9.098 6 11.04 6 12.808 6 14 7.154 14 8.727c0 1.14-.522 1.94-1.406 2.521-.864.559-1.112.948-1.112 1.705v.449h-1.386l-.007-.588c-.033-1.037.375-1.698 1.293-2.301.81-.537 1.098-.963 1.098-1.72 0-.83-.596-1.44-1.513-1.44-.925 0-1.52.61-1.58 1.58H8zM10.8 16c-.49 0-.878-.412-.878-.948 0-.537.388-.948.877-.948.502 0 .884.411.884.948 0 .536-.382.948-.884.948z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
